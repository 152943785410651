import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';

import Layout from '../components/Layout';
import MarkdownContent, { HTMLContent } from '../components/MarkdownContent';


export const ImprintPageTemplate = ({ title, html, contentComponent }) => {
  const MarkdownComponent = contentComponent || MarkdownContent;
  return (
    <div className="flex-grow-1">
      <div className="navHeight mt-3" />
      <Container className="mt-5 mb-5">
        <h1>{title}</h1>
        <div className="mt-5">
          <MarkdownComponent className="mt-5" containerClasses="d-inline" content={ html } />
        </div>
      </Container>
    </div>
  );
};

ImprintPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  html: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

ImprintPageTemplate.defaultProps = {
  contentComponent: null
};


const ImprintPage = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;

  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <ImprintPageTemplate
        title={ frontmatter.title }
        html={ html }
        contentComponent={ HTMLContent }
      />
    </Layout>
  );
};

ImprintPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.node,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export default ImprintPage;

export const pageQuery = graphql`
  query ImprintById($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "imprint-page" }, language: { eq: $language } }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        description
      }
    }
  }
`;